






















































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { AboutProjectActions } from '@store/modules/about-project/Types';
import { namespace } from 'vuex-class';

const NSProject = namespace('storeProject');
const NSUserProjects = namespace('storeUserProjects');
const aboutProjectNamespace = namespace('storeAboutProject');

import './scss/FormProjectEditGeneral.scss';
import { IStatus } from '@store/modules/user-projects/Interfaces';
import { UserProjectsActions } from '@store/modules/user-projects/Types';
import { IProject } from '@store/modules/project/Interfaces';

@Component({
    name: 'FormProjectEditGeneral',
    components: {
        BaseInput: () => import('@components/BaseInputs/BaseInput.vue'),
        BaseTextarea: () => import('@components/BaseInputs/BaseTextarea.vue'),
    }
})

export default class FormProjectEditGeneral extends Vue {
    @Prop({ default: '' }) projectId!: string | number;
    @Prop({ default: '' }) projectTitle!: string;
    @Prop({ default: '' }) address!: string;
    @Prop({ default: '' }) description!: string;
    @Prop({ default: '' }) dateStart!: string;
    @Prop({ default: '' }) dateEnd!: string;

    @NSProject.Getter('projectData') projectData!: IProject;
    @NSUserProjects.Getter('projectStatuses') projectStatuses!: IStatus[];
    @NSUserProjects.Action(UserProjectsActions.A_GET_PROJECT_STATUSES) getProjectStatuses!: () => Promise<void>;
    @aboutProjectNamespace.Action(AboutProjectActions.A_EDIT_PROJECT) editProjectData!: (payload) => Promise<void>;

    private fields: any = {
        projectTitle: this.projectTitle,
        address: this.address,
        description: this.description,
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
    }
    private errors: any = {
        projectTitle: false,
        address: false,
        description: false,
        dateStart: false,
        dateEnd: false,
    }
    private realtimeValidation = false;

    get errorExists() {
        return Object.values(this.errors).some((item) => item);
    }

    get projectStatusId() {
        const statusObjects = this.projectStatuses.filter(item => item.title === this.projectData.status);
        if (statusObjects[0]) {
            return statusObjects[0].id;
        }
        return null;
    }

    created() {
        this.getProjectStatuses();
    }

    sendForm() {
        this.validateFields();
        if (!this.errorExists) {
            this.editProjectData({
                appId: this.projectId,
                name: this.fields.projectTitle,
                objectAddress: this.fields.address,
                objectDescription: this.fields.description,
                startDate: this.fields.dateStart,
                endDate: this.fields.dateEnd,
                statusId: this.projectStatusId,
            }).then(() => {
                this.resetData();
                this.$emit('confirm-edit-project');
                this.$emit('close');
            })
        }
    }

    resetData() {
        this.realtimeValidation = false;
        this.errors = {
            projectTitle: false,
            address: false,
            description: false,
            dateStart: false,
            dateEnd: false,
        }
        this.fields.projectTitle = this.projectTitle;
        this.fields.address = this.address;
        this.fields.description = this.description;
        this.fields.dateStart = this.dateStart.replace('0001', '2000');
        this.fields.dateEnd = this.dateEnd;
    }

    validateFields() {
        this.errors.projectTitle = !this.fields.projectTitle;
        this.errors.address = !this.fields.address;
        this.errors.description = !this.fields.description;
        this.errors.dateStart = !this.fields.dateStart;
        this.errors.dateEnd = !this.fields.dateEnd;
        this.realtimeValidation = true;
    }

    checkValidation() {
        if (this.realtimeValidation) {
            this.validateFields();
        }
    }
}

